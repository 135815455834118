<template lang="pug">
div(v-if='installation')
  div(v-if='showItemPrice' class='whitespace-nowrap') + {{ formatCents(price) }}
  div(v-else class='whitespace-nowrap') + {{ formatCents(installation.subtotal) }}
  div(class='whitespace-nowrap font-normal text-xs') Installation
</template>

<script setup lang="ts">
const { installation, showItemPrice = false } = defineProps<{
  installation: Installation
  showItemPrice?: Boolean
}>()

const price = computed(() => {
  return installation.regularPrice !== installation.sellPrice ? installation.sellPrice : installation.regularPrice
})
</script>
